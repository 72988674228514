import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Typography } from "@progress/kendo-react-common";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import moment from "moment";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { clearSchemeDetails } from "./schemeSlice";
import {
  createScheme,
  getSchemeByID,
  updateScheme,
} from "./services/scheme.services";
import ShadowCard from "../../components/common/ShadowCard";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import FormDatePicker from "../../components/formFields/FormDateField";
import {
  ExpansionPanel,
  ExpansionPanelActionEvent,
  ExpansionPanelContent,
} from "@progress/kendo-react-layout";
import { Reveal } from "@progress/kendo-react-animation";
import { getAllItemMarginRates } from "../placeorder/services/order.services";
import FormTextField from "../../components/formFields/FormTextField";
import FormTextArea from "../../components/formFields/FormTextArea";
import FormCheckbox from "../../components/formFields/FormCheckbox";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import { LoadingPanel } from "../../components/layout/Loading";
import { ErrorToast } from "../../components/toast/Toasts";

const CreateScheme: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const scheme_guid = location.state?.scheme_guid;
  const gridRef = useRef<any>(null);
  const AccountIDString = useAppSelector(
    (state) => state.login.loginDetails.AccountID
  );
  const AccountID = parseInt(AccountIDString);
  const UserTypeID = localStorage.getItem("UserTypeID");

  const loading = useAppSelector((state) => state.scheme.loading);
  const MarginRateList = useAppSelector((state) => state.order.MarginRateList);
  const SchemeDetail = useAppSelector((state) => state.scheme.SchemeDetail);

  const [initialValues, setInitialValues] = useState({});
  const [formKey, setFormKey] = useState(1);
  const [RateData, setRateData] = useState<any[]>(
    JSON.parse(JSON.stringify(MarginRateList))
  );
  const [expanded, setExpanded] = React.useState<any>(0);
  const [openEdit, setOpenEdit] = useState(false);

  useEffect(() => {
    if (UserTypeID) {
      dispatch(getAllItemMarginRates(+UserTypeID));
    }
  }, [UserTypeID]);

  useEffect(() => {
    return () => {
      dispatch(clearSchemeDetails());
    };
  }, []);

  useEffect(() => {
    if (scheme_guid) {
      dispatch(getSchemeByID(scheme_guid));
    }
  }, [scheme_guid]);

  useEffect(() => {
    const updatedMarginRatelist = JSON.parse(JSON.stringify(MarginRateList));
    const updatedData = updatedMarginRatelist.map((itemGroup: any) => {
      const updatedItems = itemGroup.item_margin_rate.map((item: any) => {
        SchemeDetail?.scheme_items?.forEach((printGroup: any) => {
          if (item.item_id === printGroup.item_id) {
            item.selected = true;
          }
        });
        return item;
      });
      return {
        ...itemGroup,
        item_margin_rate: updatedItems,
      };
    });

    setRateData(updatedData);
  }, [MarginRateList, SchemeDetail]);

  useEffect(() => {
    const newInitialValues =
      SchemeDetail &&
      SchemeDetail?.scheme_items &&
      SchemeDetail?.scheme_items?.reduce((acc: any, item: any) => {
        return {
          ...SchemeDetail,
          ...acc,
          [`selected_${item?.item_id}`]: true,
        };
      }, {});

    if (JSON.stringify(newInitialValues) !== JSON.stringify(initialValues)) {
      setInitialValues(newInitialValues);
      setFormKey((prevKey) => prevKey + 1);
    }
  }, [SchemeDetail]);

  useEffect(() => {
    setFormKey(formKey + 1);
  }, []);

  const handleQtyChange = (
    formRenderProps: FormRenderProps,
    mainIndex: number,
    subIndex: number,
    itemId: number
  ) => {
    const selected_row = formRenderProps.valueGetter(`selected_${itemId}`);
    RateData[mainIndex].item_margin_rate[subIndex].selected = selected_row;
  };

  const handleSubmit = async (values: any) => {
    const updatedValue: any = RateData?.map((item: any) => ({
      ...item,
      item_margin_rate: item?.item_margin_rate?.filter(
        (subItem: any) => subItem.selected === true
      ),
    }))?.filter((item: any) => item?.item_margin_rate?.length > 0);

    const place_order_items =
      updatedValue &&
      updatedValue.length > 0 &&
      updatedValue?.flatMap(
        (schemeitem: any) =>
          schemeitem?.item_margin_rate &&
          schemeitem?.item_margin_rate.length > 0 &&
          schemeitem?.item_margin_rate?.map((item: any) => {
            return {
              item_id: item?.item_id ? +item?.item_id : null,
              mrp: item?.mrp ? +item?.mrp : null,
              unit_id: item?.unit_id ? +item?.unit_id : null,
              rate: item?.rate ? +item?.rate : null,
            };
          })
      );
    if (!place_order_items) {
      ErrorToast("Please select atleast one item.");
    } else {
      if (scheme_guid) {
        const editPayload: any = {
          id: values?.id ? +values?.id : null,
          scheme_guid: scheme_guid ? scheme_guid : "",
          scheme_name: values?.scheme_name ? values?.scheme_name : "",
          from_date: values?.from_date
            ? moment(values?.from_date).format("YYYY-MM-DD")
            : "",
          to_date: values?.to_date
            ? moment(values?.to_date).format("YYYY-MM-DD")
            : "",
          remarks: values?.remarks ? values?.remarks : "",
          scheme_items: place_order_items || [],
        };

        const response = await dispatch(updateScheme(editPayload));
        if (response.meta.requestStatus === "fulfilled") {
          navigate("/scheme");
        }
      } else {
        const insertPayload: any = {
          scheme_name: values?.scheme_name ? values?.scheme_name : "",
          from_date: values?.from_date
            ? moment(values?.from_date).format("YYYY-MM-DD")
            : "",
          to_date: values?.to_date
            ? moment(values?.to_date).format("YYYY-MM-DD")
            : "",
          remarks: values?.remarks ? values?.remarks : "",
          scheme_items: place_order_items || [],
        };

        const response = await dispatch(createScheme(insertPayload));
        if (response.meta.requestStatus === "fulfilled") {
          navigate("/scheme");
        }
      }
    }
  };

  return (
    <>
      {loading && <LoadingPanel gridRef={gridRef} />}
      <div className={`${AccountID ? "container p-2" : ""}`}>
        <Form
          key={formKey}
          onSubmit={handleSubmit}
          initialValues={initialValues}
          render={(formRenderProps: FormRenderProps) => (
            <FormElement
              style={{
                overflow: "scroll",
                scrollbarWidth: "none",
                boxShadow: "lightgray 0px 0px 10px",
                borderRadius: 10,
                background: "white",
              }}
            >
              <ShadowCard style={{ padding: 12, marginBottom: 10 }}>
                <GridLayout
                  style={{ marginRight: 20 }}
                  gap={{ rows: 0, cols: 10 }}
                  cols={[
                    { width: "33.33%" },
                    { width: "33.33%" },
                    { width: "33.33%" },
                  ]}
                >
                  <GridLayoutItem colSpan={3}>
                    <Typography.h4>
                      {scheme_guid ? "Update Scheme" : "Add Scheme"}
                    </Typography.h4>
                  </GridLayoutItem>
                  <GridLayoutItem colSpan={3} className="row">
                    <div className="col-md-4 pe-md-0">
                      <Field
                        name="scheme_name"
                        label="Scheme Name"
                        placeholder="Scheme Name"
                        component={FormTextField}
                        validator={requiredValidator}
                        astrike={true}
                      />
                    </div>
                    <div className="col-md-4 pe-md-0">
                      <Field
                        name="from_date"
                        label="From Date"
                        format="dd/MM/yyyy"
                        component={FormDatePicker}
                        validator={requiredValidator}
                        astrike={true}
                      />
                    </div>
                    <div className="col-md-4 ">
                      <Field
                        name="to_date"
                        label="To Date"
                        format="dd/MM/yyyy"
                        component={FormDatePicker}
                        validator={requiredValidator}
                        astrike={true}
                        minDate={moment(
                          formRenderProps.valueGetter("from_date")
                        ).toDate()}
                      />
                    </div>
                    <div className="col-md-6">
                      <Field
                        name="remarks"
                        label="Remarks"
                        rows={2}
                        placeholder="Remarks"
                        component={FormTextArea}
                      />
                    </div>
                  </GridLayoutItem>
                </GridLayout>
              </ShadowCard>

              {scheme_guid && (
                <ShadowCard style={{ padding: 12, marginBottom: 10 }}>
                  <GridLayout
                    style={{ marginRight: 20 }}
                    gap={{ rows: 0, cols: 10 }}
                    cols={[
                      { width: "33.33%" },
                      { width: "33.33%" },
                      { width: "33.33%" },
                    ]}
                  >
                    <GridLayoutItem
                      colSpan={3}
                      className="d-flex justify-content-between align-items-center pb-2"
                    >
                      <Typography.h5 className="m-0">
                        Scheme Items
                      </Typography.h5>
                      <Button
                        type="button"
                        themeColor={"primary"}
                        fillMode={"solid"}
                        onClick={() => {
                          setOpenEdit(!openEdit);
                        }}
                      >
                        {openEdit ? "Cancel" : "Edit"}
                      </Button>
                    </GridLayoutItem>
                    <GridLayoutItem
                      colSpan={3}
                      style={{ overflow: "scroll", scrollbarWidth: "none" }}
                    >
                      <div className="row">
                        <div className="col-12">
                          <table
                            cellSpacing={1}
                            cellPadding={2}
                            className="table w-100 scheme-table"
                            style={{ marginTop: 1 }}
                          >
                            <tr
                              className="text-center fw-600 text-white bg-dark"
                              style={{ fontSize: 12 }}
                            >
                              <th
                                className="pb-2 pt-2"
                                style={{ width: "10%" }}
                              >
                                No.
                              </th>
                              <th style={{ width: "45%" }}>
                                Description Of Goods
                              </th>
                              <th style={{ width: "15%" }}>MRP</th>
                              <th style={{ width: "15%" }}>Unit</th>
                              <th style={{ width: "15%" }}>Rate</th>
                              {/* <th style={{ width: "30%" }}>Packing</th> */}
                            </tr>
                            {SchemeDetail &&
                            SchemeDetail?.scheme_items &&
                            SchemeDetail?.scheme_items?.length > 0 ? (
                              SchemeDetail?.scheme_items?.map(
                                (scheme: any, index: number) => (
                                  <tr>
                                    <td className="text-center">{index + 1}</td>
                                    <td>{scheme?.product_name}</td>
                                    <td>
                                      {scheme?.mrp ? `Rs. ${scheme?.mrp}` : ""}
                                    </td>
                                    <td>{scheme?.unit_code}</td>
                                    <td>
                                      {scheme?.rate
                                        ? `Rs. ${scheme?.rate}`
                                        : ""}
                                    </td>
                                    {/* <td>{scheme?.item_type}</td> */}
                                  </tr>
                                )
                              )
                            ) : (
                              <tr>
                                <td colSpan={5} className="text-center">
                                  No Data Found
                                </td>
                              </tr>
                            )}
                          </table>
                        </div>
                      </div>
                    </GridLayoutItem>
                    {(!scheme_guid || (scheme_guid && !openEdit)) && (
                      <GridLayoutItem colSpan={3}>
                        <div
                          className="col-12"
                          style={{
                            textAlign: "end",
                            width: "100%",
                          }}
                        >
                          <ButtonWithLoading
                            label={scheme_guid ? "Update" : "Save"}
                            type="submit"
                            disabled={!formRenderProps.allowSubmit || loading}
                            loading={loading}
                          />
                          <Button
                            type="button"
                            fillMode={"outline"}
                            themeColor={"primary"}
                            style={{ marginLeft: 4 }}
                            onClick={() => navigate(-1)}
                          >
                            Cancel
                          </Button>
                        </div>
                      </GridLayoutItem>
                    )}
                  </GridLayout>
                </ShadowCard>
              )}

              {(!scheme_guid || (scheme_guid && openEdit)) && (
                <ShadowCard style={{ padding: 12, marginBottom: 10 }}>
                  <GridLayout
                    style={{ marginRight: 20 }}
                    gap={{ rows: 0, cols: 10 }}
                    cols={[
                      { width: "33.33%" },
                      { width: "33.33%" },
                      { width: "33.33%" },
                    ]}
                  >
                    <GridLayoutItem
                      colSpan={3}
                      style={{ overflow: "scroll", scrollbarWidth: "none" }}
                    >
                      <div
                        className="container-fluid orderform"
                        style={{ padding: "0", minWidth: 800 }}
                      >
                        <div className="row">
                          <div className="col-12">
                            <table
                              cellSpacing={1}
                              cellPadding={2}
                              className="table table-bordered w-100  salesorder-print-table"
                              style={{ marginTop: 1 }}
                            >
                              <tr
                                className="text-center fw-600 text-white bg-dark"
                                style={{ fontSize: 12 }}
                              >
                                <th
                                  className="pb-3 pt-3"
                                  style={{ width: "5%" }}
                                ></th>
                                <th
                                  className="pb-3 pt-3"
                                  style={{ width: "10%" }}
                                >
                                  No.
                                </th>
                                <th style={{ width: "45%" }}>
                                  Description Of Goods
                                </th>
                                <th style={{ width: "10%" }}>MRP</th>
                                <th style={{ width: "10%" }}>Unit</th>
                                <th style={{ width: "10%" }}>Rate</th>
                              </tr>
                              {RateData && RateData.length > 0 ? (
                                RateData?.map(
                                  (item: any, mainIndex: number) => (
                                    <tr key={mainIndex}>
                                      <td colSpan={7} className="p-0">
                                        <ExpansionPanel
                                          title={item?.item_group}
                                          expanded={expanded === mainIndex}
                                          tabIndex={0}
                                          key={item.id}
                                          onAction={(
                                            event: ExpansionPanelActionEvent
                                          ) => {
                                            setExpanded(
                                              event.expanded ? null : mainIndex
                                            );
                                          }}
                                        >
                                          <Reveal>
                                            {expanded === mainIndex && (
                                              <ExpansionPanelContent>
                                                {item?.item_margin_rate &&
                                                  item?.item_margin_rate
                                                    ?.length > 0 &&
                                                  item?.item_margin_rate?.map(
                                                    (
                                                      subitem: any,
                                                      subIndex: number
                                                    ) => (
                                                      <div
                                                        key={subIndex}
                                                        id={`collapse${mainIndex}`}
                                                      >
                                                        <div
                                                          style={{
                                                            padding: "5px 0",
                                                          }}
                                                        >
                                                          <table
                                                            style={{
                                                              width: "100%",
                                                            }}
                                                          >
                                                            <tr>
                                                              <td
                                                                style={{
                                                                  width: "5%",
                                                                }}
                                                              >
                                                                <Field
                                                                  wrapperClassName="d-flex justify-content-center scheme-checkbox"
                                                                  wrapperStyle={{
                                                                    margin: 0,
                                                                  }}
                                                                  // name={`selected_${item?.item_group_id}_${subitem?.item_id}`}
                                                                  name={`selected_${subitem?.item_id}`}
                                                                  placeholder="0"
                                                                  onChange={() =>
                                                                    handleQtyChange(
                                                                      formRenderProps,
                                                                      mainIndex,
                                                                      subIndex,
                                                                      subitem?.item_id
                                                                    )
                                                                  }
                                                                  component={
                                                                    FormCheckbox
                                                                  }
                                                                />
                                                              </td>
                                                              <td
                                                                style={{
                                                                  width: "10%",
                                                                }}
                                                                className="text-center"
                                                              >
                                                                {subIndex + 1}
                                                              </td>
                                                              <td
                                                                style={{
                                                                  width: "45%",
                                                                }}
                                                              >
                                                                {
                                                                  subitem?.product_name
                                                                }
                                                              </td>
                                                              <td
                                                                style={{
                                                                  width: "10%",
                                                                  textAlign:
                                                                    "center",
                                                                }}
                                                              >
                                                                Rs.{" "}
                                                                {subitem?.mrp ||
                                                                  0}
                                                              </td>
                                                              <td
                                                                style={{
                                                                  width: "10%",
                                                                  textAlign:
                                                                    "center",
                                                                }}
                                                              >
                                                                {
                                                                  subitem?.unit_code
                                                                }
                                                              </td>
                                                              <td
                                                                style={{
                                                                  width: "10%",
                                                                  textAlign:
                                                                    "center",
                                                                }}
                                                              >
                                                                Rs.{" "}
                                                                {subitem?.rate ||
                                                                  0}
                                                              </td>
                                                            </tr>
                                                          </table>
                                                        </div>
                                                      </div>
                                                    )
                                                  )}
                                              </ExpansionPanelContent>
                                            )}
                                          </Reveal>
                                        </ExpansionPanel>
                                      </td>
                                    </tr>
                                  )
                                )
                              ) : (
                                <tr>
                                  <td colSpan={7} className="text-center p-2">
                                    No Records Found
                                  </td>
                                </tr>
                              )}
                            </table>
                          </div>
                          <div
                            className="col-12"
                            style={{
                              textAlign: "end",
                              marginTop: "20px",
                              width: "100%",
                            }}
                          >
                            <ButtonWithLoading
                              label={scheme_guid ? "Update" : "Save"}
                              type="submit"
                              disabled={!formRenderProps.allowSubmit || loading}
                              loading={loading}
                            />
                            <Button
                              type="button"
                              fillMode={"outline"}
                              themeColor={"primary"}
                              style={{ marginLeft: 4 }}
                              onClick={() => navigate(-1)}
                            >
                              Cancel
                            </Button>
                          </div>
                        </div>
                      </div>
                    </GridLayoutItem>
                  </GridLayout>
                </ShadowCard>
              )}
            </FormElement>
          )}
        />
      </div>
    </>
  );
};

export default CreateScheme;
