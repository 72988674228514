import React, { useCallback, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { LoadingPanel } from "../../components/layout/Loading";
import { Typography } from "@progress/kendo-react-common";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  FieldArray,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import FormTextField from "../../components/formFields/FormTextField";
import {
  emailWOValueValidator,
  phoneWOValueValidator,
  requiredValidator,
} from "../../components/formFields/CommonValidator";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import ShadowCard from "../../components/common/ShadowCard";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import FormDatePicker from "../../components/formFields/FormDateField";
import {
  getAccountByID,
  getAllAccountIncremental,
} from "../account/services/account.services";
import { getAllItemIncremental } from "../Item/services/item.services";
import { findAllActiveUnit } from "../unit/services/unit.services";
import ButtonForAll from "../../components/common/Button";
import { clearItemList } from "../Item/itemSlice";
import FormIncrementalSearch from "../../components/formFields/FormIncrementalSearch";
import {
  createPurchase,
  generatePurchaseNoForPurchase,
  getPurchaseByID,
  updatePurchase,
} from "./services/purchase.services";
import { clearPurchaseDetails, setPurchaseStateForGST } from "./purchaseSlice";
import { getAllActiveGST } from "../gst/services/gst.services";
import FormNumericTextField from "../../components/formFields/FormNumericTextField";
import { FINANCIAL_YEAR, GUJARATIDFORGST } from "../../_contstants/common";
import moment from "moment";
import {
  getAllLimitedInwards,
  getInwardByID,
} from "../inward/services/inward.services";
import PurchaseItemDetailsArray from "./PurchaseItemDetailsArray";
import { clearInwardDetails } from "../inward/inwardSlice";
import { getAllActiveCities } from "../city/services/city.services";
import AlertBox from "../../components/common/AlertBox";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import {
  formatIndianNumber,
  formatIndianNumberForQty,
} from "../../_helper/helper";
import { openDialog } from "../../components/dialog/dialogSlice";
import { getAllActiveStates } from "../state/services/state.services";
import { IState } from "../state/stateModel";
import { getAllActiveAccountGroup } from "../accountgroup/services/accountGroup.services";
import { IAccountGroup } from "../accountgroup/accountgroupModel";

interface FormChangeWatcherProps {
  formRenderProps: FormRenderProps;
}
const QtyChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const purchase_items = formRenderProps.valueGetter("purchase_items");
  const taxable_amount = formRenderProps.valueGetter("taxable_amount");
  const gst = formRenderProps.valueGetter("gst");
  const packing = formRenderProps.valueGetter("packing");
  const labour = formRenderProps.valueGetter("labour");
  const dalali = formRenderProps.valueGetter("dalali");
  const sub_total = formRenderProps.valueGetter("sub_total");
  const round_off = formRenderProps.valueGetter("round_off");
  const PurchaseStateForGST = useAppSelector(
    (state) => state.purchase.PurchaseStateForGST
  );

  useEffect(() => {
    let totalGSTamount = 0;
    let totalGST5 = 0;
    let totalGST12 = 0;
    let totalGST18 = 0;
    let totalGST28 = 0;
    purchase_items?.map((item: any, index: number) => {
      const gst_id = item?.gst_id;
      const amount = item?.amount;
      const gstid = gst_id ? parseInt(gst_id?.split("--")[1]) : 0;
      if (gstid === 5) {
        totalGST5 += (+amount * +gstid) / 100;
      } else if (gstid === 12) {
        totalGST12 += (+amount * +gstid) / 100;
      } else if (gstid === 18) {
        totalGST18 += (+amount * +gstid) / 100;
      } else if (gstid === 28) {
        totalGST28 += (+amount * +gstid) / 100;
      }
      totalGSTamount += (+amount * +gstid) / 100;
      if (!gst_id) {
        formRenderProps.onChange(`purchase_items.${index}.gst_id`, {
          value: null,
        });
      }
    });
    formRenderProps.onChange("gst", {
      value: totalGSTamount || 0,
    });
    if (PurchaseStateForGST === GUJARATIDFORGST) {
      formRenderProps.onChange("cgst", {
        value: totalGSTamount / 2 || 0,
      });
      formRenderProps.onChange("sgst", {
        value: totalGSTamount / 2 || 0,
      });
    } else {
      formRenderProps.onChange("igst", {
        value: totalGSTamount || 0,
      });
    }
    formRenderProps.onChange("gst5", {
      value: totalGST5 || 0,
    });
    formRenderProps.onChange("gst12", {
      value: totalGST12 || 0,
    });
    formRenderProps.onChange("gst18", {
      value: totalGST18 || 0,
    });
    formRenderProps.onChange("gst28", {
      value: totalGST28 || 0,
    });
  }, [
    PurchaseStateForGST,
    purchase_items?.map((item: any) => item?.gst_id).join("-"),
    purchase_items?.map((item: any) => item?.amount).join("-"),
  ]);

  useEffect(() => {
    if (purchase_items && purchase_items.length > 0) {
      purchase_items?.map((item: any, index: number) => {
        const quantity = item?.quantity || 0;
        const rate = item?.rate || 0;
        // const discount = (+item?.quantity * +item?.rate * item?.discount) / 100 || 0;
        // formRenderProps.onChange(`purchase_items.${index}.amount`, {
        //   value: +quantity * +rate - +discount,
        // });
        const discount = item?.discount || 0;
        const discount_type = item?.discount_type || "₹";
        if (discount_type && discount_type === "₹") {
          formRenderProps.onChange(`purchase_items.${index}.amount`, {
            value:
              +quantity * +rate > +discount ? +quantity * +rate - +discount : 0,
          });
        } else if (discount_type && discount_type === "%") {
          let total = +quantity * +rate;
          formRenderProps.onChange(`purchase_items.${index}.amount`, {
            value:
              +quantity * +rate > (+total * +discount) / 100
                ? +quantity * +rate - (+total * +discount) / 100
                : 0,
          });
        } else {
          formRenderProps.onChange(`purchase_items.${index}.amount`, {
            value: +quantity * +rate,
          });
        }
      });
    }
  }, [
    purchase_items.map((item: any) => item?.quantity).join("-"),
    purchase_items.map((item: any) => item?.rate).join("-"),
    purchase_items.map((item: any) => item?.discount).join("-"),
    purchase_items.map((item: any) => item?.discount_type).join("-"),
  ]);

  let totalamount = 0;
  let totalQty = 0;

  useEffect(() => {
    if (purchase_items && purchase_items.length > 0) {
      purchase_items?.map((item: any) => {
        totalQty += +item?.quantity || 0;
        totalamount += +item?.amount || 0;
      });
    }
    formRenderProps.onChange("totalQty", {
      value: totalQty,
    });
    formRenderProps.onChange("taxable_amount", {
      value: totalamount,
    });
  }, [
    purchase_items.map((item: any) => item?.quantity).join("-"),
    purchase_items.map((item: any) => item?.amount).join("-"),
  ]);

  useEffect(() => {
    formRenderProps.onChange("sub_total", {
      value: +taxable_amount + +gst + +packing + +labour + +dalali || 0,
    });
    formRenderProps.onChange("round_off", {
      value: Math.round(+sub_total) - +sub_total,
    });
    formRenderProps.onChange("grand_total", {
      value: +sub_total + +round_off || 0,
    });
  }, [taxable_amount, gst, packing, labour, dalali, sub_total, round_off]);

  return null;
};

const VendorChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const purchase_guid = location.state?.purchase_guid;
  const vendor_id = formRenderProps.valueGetter("vendor_id");
  const isVendorRef = useRef(true);

  useEffect(() => {
    if (vendor_id) {
      const handleVendorChange = async () => {
        const payload = {
          id: vendor_id,
        };
        const response = await dispatch(getAccountByID(payload));
        if (response.meta.requestStatus === "fulfilled") {
          dispatch(setPurchaseStateForGST(response.payload?.state_id));
          formRenderProps.onChange("state_id", {
            value: response.payload?.state_id,
          });
          formRenderProps.onChange("terms_days", {
            value: response.payload?.payment_due_days,
          });
          formRenderProps.onChange("email_id", {
            value: response.payload?.email,
          });
          formRenderProps.onChange("mobile_no", {
            value: response.payload?.mobile_number,
          });
          formRenderProps.onChange("place_of_supply_id", {
            value: response.payload?.state_id,
          });
          // formRenderProps.onChange("pan_no", {
          //   value: response.payload?.pan_no,
          // });
          // formRenderProps.onChange("gst_no", {
          //   value: response.payload?.gst_no,
          // });
        } else {
          formRenderProps.onChange("state_id", {
            value: null,
          });
          formRenderProps.onChange("terms_days", {
            value: null,
          });
          formRenderProps.onChange("email_id", {
            value: null,
          });
          formRenderProps.onChange("mobile_no", {
            value: null,
          });
          formRenderProps.onChange("place_of_supply_id", {
            value: null,
          });
          // formRenderProps.onChange("pan_no", {
          //   value: null,
          // });
          // formRenderProps.onChange("gst_no", {
          //   value: null,
          // });
        }
      };
      if (!isVendorRef.current) {
        handleVendorChange();
      } else {
        if (!purchase_guid) {
          handleVendorChange();
        }
        isVendorRef.current = false;
      }
    } else {
      dispatch(setPurchaseStateForGST(null));
      formRenderProps.onChange("state_id", {
        value: "",
      });
      formRenderProps.onChange("terms_days", {
        value: "",
      });
      formRenderProps.onChange("email_id", {
        value: "",
      });
      formRenderProps.onChange("mobile_no", {
        value: "",
      });
      formRenderProps.onChange("place_of_supply_id", {
        value: "",
      });
      // formRenderProps.onChange("pan_no", {
      //   value: "",
      // });
      // formRenderProps.onChange("gst_no", {
      //   value: "",
      // });
    }
  }, [vendor_id]);

  return null;
};

const DateChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const location = useLocation();
  const purchase_guid = location.state?.purchase_guid;
  const invoice_date = formRenderProps.valueGetter("invoice_date");
  const terms_days = formRenderProps.valueGetter("terms_days");
  // const due_date = formRenderProps.valueGetter("due_date");
  const isDateRef = useRef(true);

  useEffect(() => {
    const changeDueDate = () => {
      if (terms_days) {
        const dueDate = moment(invoice_date, "YYYY-MM-DD").add(
          terms_days || 0,
          "days"
        );

        formRenderProps.onChange("due_date", {
          value: moment(dueDate).toDate() || "",
        });
      }
      if (!terms_days) {
        formRenderProps.onChange("terms_days", {
          value: "",
        });
        formRenderProps.onChange("due_date", {
          value: "",
        });
      }
    };

    if (!isDateRef.current) {
      changeDueDate();
    } else {
      if (!purchase_guid) {
        changeDueDate();
      }
      isDateRef.current = false;
    }
  }, [invoice_date, terms_days]);

  return null;
};

const PurchaseNoChangeWatcher: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const purchase_guid = location.state?.purchase_guid;
  const isPurchaseNoRef = useRef(false);

  useEffect(() => {
    const fetchInvoiceNoDate = async () => {
      const response = await dispatch(generatePurchaseNoForPurchase());
      if (response.meta.requestStatus === "fulfilled") {
        formRenderProps.onChange("purchase_no", {
          value: response.payload?.purchase_no || "",
        });
      }
    };
    if (!isPurchaseNoRef.current) {
      if (!purchase_guid) {
        fetchInvoiceNoDate();
      }
      isPurchaseNoRef.current = true;
    } else {
      isPurchaseNoRef.current = true;
    }
  }, []);

  return null;
};

const CreatePurchase = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const purchase_guid = location.state?.purchase_guid;
  const gridRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.purchase.loading);
  const StateList = useAppSelector((state) => state.state.StateList);
  const PurchaseDetail = useAppSelector(
    (state) => state.purchase.PurchaseDetail
  );
  const PurchaseStateForGST = useAppSelector(
    (state) => state.purchase.PurchaseStateForGST
  );
  const AccountGroupList = useAppSelector(
    (state) => state.accountGroup.AccountGroupList
  );

  const InwardList = useAppSelector((state) => state.inward.InwardList);
  const [formKey, setFormKey] = React.useState(1);

  useEffect(() => {
    setFormKey(formKey + 1);
  }, [PurchaseDetail]);

  const isGSTRef = useRef(true);
  const [isIGST, setIsIgst] = React.useState<boolean>(false);
  useEffect(() => {
    if (purchase_guid) {
      if (!isGSTRef.current) {
        setIsIgst(PurchaseStateForGST !== GUJARATIDFORGST);
      } else {
        if (PurchaseDetail?.igst && PurchaseDetail?.igst !== null) {
          setIsIgst(true);
        } else {
          setIsIgst(false);
        }
        isGSTRef.current = false;
      }
    } else {
      setIsIgst(PurchaseStateForGST !== GUJARATIDFORGST);
    }
  }, [PurchaseStateForGST]);

  console.log("isIGST", isIGST);

  useEffect(() => {
    if (purchase_guid) {
      const payload = {
        purchase_guid: purchase_guid,
      };
      dispatch(getPurchaseByID(payload));
    }
  }, [purchase_guid]);

  useEffect(() => {
    const payload = {
      financial_year: FINANCIAL_YEAR,
    };

    dispatch(findAllActiveUnit());
    dispatch(getAllActiveGST());
    dispatch(getAllActiveCities());
    dispatch(getAllActiveStates());
    dispatch(getAllActiveAccountGroup());
    dispatch(getAllLimitedInwards(payload));

    return () => {
      dispatch(clearPurchaseDetails());
      dispatch(clearInwardDetails());
      dispatch(clearItemList());
    };
  }, []);

  useEffect(() => {
    const fetchAccountDetails = async () => {
      const payload = {
        id: PurchaseDetail?.vendor_id,
      };
      const response = await dispatch(getAccountByID(payload));
      if (response.meta.requestStatus === "fulfilled") {
        dispatch(setPurchaseStateForGST(response.payload?.state_id));
      } else {
        dispatch(setPurchaseStateForGST(null));
      }
    };
    if (PurchaseDetail?.vendor_id) {
      fetchAccountDetails();
    }
  }, [PurchaseDetail]);

  const handleChallanNoChange = async (
    inward_no: any,
    formRenderProps: FormRenderProps
  ) => {
    if (inward_no) {
      const payload = {
        inward_no: inward_no,
        financial_year: FINANCIAL_YEAR,
      };
      const response = await dispatch(getInwardByID(payload));
      if (response.meta.requestStatus === "fulfilled") {
        formRenderProps.onChange("vendor_id", {
          value: response.payload?.vendor_id,
        });
        formRenderProps.onChange("po_no", {
          value: response.payload?.po_no,
        });
        formRenderProps.onChange("vendor_options", {
          value: response.payload?.vendor_options,
        });
        formRenderProps.onChange("invoice_no", {
          value: response.payload?.bill_no,
        });
        formRenderProps.onChange("invoice_date", {
          value: response.payload?.bill_date,
        });
        const purchaseItems =
          response.payload?.inward_items &&
          response.payload?.inward_items.length > 0 &&
          response.payload?.inward_items?.map((item: any) => {
            return {
              item_id: item?.item_id,
              item_options: item?.item_options,
              quantity: item?.weight,
              unit_id: item?.unit_id,
              rate: item?.rate,
              gst_id: `${item?.gst_id}--${item?.gst || "0"}`,
              amount: item?.total,
            };
          });
        formRenderProps.onChange("purchase_items", {
          value: purchaseItems || [],
        });
      } else {
        formRenderProps.onChange("vendor_id", {
          value: null,
        });
        formRenderProps.onChange("po_no", {
          value: null,
        });
        formRenderProps.onChange("vendor_options", {
          value: [],
        });
      }
    }
  };

  const handleItemSearchChange = useCallback(
    async (search: string, field: string, formRenderProps: FormRenderProps) => {
      const result = await dispatch(getAllItemIncremental({ search }));
      formRenderProps.onChange(field, {
        value: result.payload,
      });
    },
    [dispatch]
  );

  const handleVendorSearchChange = useCallback(
    async (search: string, field: string, formRenderProps: FormRenderProps) => {
      const result = await dispatch(getAllAccountIncremental(search));
      formRenderProps.onChange(field, {
        value: result.payload,
      });
    },
    [dispatch]
  );

  const handleSubmit = async (values: any) => {
    if (purchase_guid) {
      try {
        const updatePayload: any = {
          id: values?.id ? +values?.id : null,
          purchase_guid: purchase_guid,
          purchase_challan_id: values?.purchase_challan_id
            ? +values?.purchase_challan_id
            : null,
          financial_year: FINANCIAL_YEAR,
          inward_no: values?.inward_no ? +values?.inward_no : null,
          mobile_no: values?.mobile_no ? `${values?.mobile_no}` : "",
          email_id: values?.email_id ? values?.email_id : "",
          // pan_no: values?.pan_no ? values?.pan_no : "",
          // gst_no: values?.gst_no ? values?.gst_no : "",
          invoice_no: values?.invoice_no ? values?.invoice_no : "",
          cartoon: values?.cartoon ? +values?.cartoon : null,
          bag: values?.bag ? +values?.bag : null,
          vehicle_no: values?.vehicle_no ? values?.vehicle_no : "",
          transport_name: values?.transport_name ? values?.transport_name : "",
          delivery_slip_no: values?.delivery_slip_no
            ? values?.delivery_slip_no
            : "",
          invoice_date: values?.invoice_date
            ? moment(values?.invoice_date).format("YYYY-MM-DD")
            : "",
          vendor_id: values?.vendor_id ? +values?.vendor_id : null,
          terms_days: values?.terms_days ? +values?.terms_days : null,
          due_date: values?.due_date
            ? moment(values?.due_date).format("YYYY-MM-DD")
            : null,
          place_of_supply_id: values?.place_of_supply_id
            ? +values?.place_of_supply_id
            : null,
          purchase_ledger_id: values?.purchase_ledger_id
            ? +values?.purchase_ledger_id
            : null,
          packing: values?.packing ? +values?.packing?.toFixed(2) : null,
          labour: values?.labour ? +values?.labour?.toFixed(2) : null,
          dalali: values?.dalali ? +values?.dalali?.toFixed(2) : null,
          total_amount: values?.grand_total ? +values?.grand_total : null,
          gst: values?.gst ? +values?.gst?.toFixed(2) : null,
          purchase_items: values?.purchase_items
            ? values?.purchase_items?.map((purchaseitem: any) => {
                return {
                  id: purchaseitem?.id ? +purchaseitem?.id : 0,
                  purchase_id: values?.id ? +values?.id : null,
                  item_id: purchaseitem?.item_id
                    ? +purchaseitem?.item_id
                    : null,
                  // mrp: purchaseitem?.mrp ? +purchaseitem?.mrp : null,
                  quantity: purchaseitem?.quantity
                    ? +purchaseitem?.quantity
                    : 0,
                  free_quantity: purchaseitem?.free_quantity
                    ? +purchaseitem?.free_quantity
                    : 0,
                  unit_id: purchaseitem?.unit_id
                    ? +purchaseitem?.unit_id
                    : null,
                  rate: purchaseitem?.rate ? +purchaseitem?.rate : null,
                  discount: purchaseitem?.discount
                    ? +purchaseitem?.discount
                    : null,
                  discount_type: purchaseitem?.discount_type === "%" ? 2 : 1,
                  gst_id: purchaseitem?.gst_id
                    ? parseInt(purchaseitem?.gst_id.split("--")[0])
                    : null,
                  amount: purchaseitem?.amount ? +purchaseitem?.amount : null,
                };
              })
            : [],
        };
        if (PurchaseStateForGST === GUJARATIDFORGST) {
          updatePayload.cgst = values?.cgst ? +values?.cgst?.toFixed(2) : null;
          updatePayload.sgst = values?.sgst ? +values?.sgst?.toFixed(2) : null;
          updatePayload.igst = null;
        } else {
          updatePayload.igst = values?.igst ? +values?.igst?.toFixed(2) : null;
          updatePayload.cgst = null;
          updatePayload.sgst = null;
        }

        const response = await dispatch(updatePurchase(updatePayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate("/purchase");
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    } else {
      try {
        const insertPayload: any = {
          purchase_challan_id: values?.purchase_challan_id
            ? +values?.purchase_challan_id
            : null,
          financial_year: FINANCIAL_YEAR,
          inward_no: values?.inward_no ? +values?.inward_no : null,
          mobile_no: values?.mobile_no ? `${values?.mobile_no}` : "",
          email_id: values?.email_id ? values?.email_id : "",
          // pan_no: values?.pan_no ? values?.pan_no : "",
          // gst_no: values?.gst_no ? values?.gst_no : "",
          invoice_no: values?.invoice_no ? values?.invoice_no : "",
          cartoon: values?.cartoon ? +values?.cartoon : null,
          bag: values?.bag ? +values?.bag : null,
          vehicle_no: values?.vehicle_no ? values?.vehicle_no : "",
          transport_name: values?.transport_name ? values?.transport_name : "",
          delivery_slip_no: values?.delivery_slip_no
            ? values?.delivery_slip_no
            : "",
          invoice_date: values?.invoice_date
            ? moment(values?.invoice_date).format("YYYY-MM-DD")
            : "",
          vendor_id: values?.vendor_id ? +values?.vendor_id : null,
          terms_days: values?.terms_days ? +values?.terms_days : null,
          due_date: values?.due_date
            ? moment(values?.due_date).format("YYYY-MM-DD")
            : null,
          place_of_supply_id: values?.place_of_supply_id
            ? +values?.place_of_supply_id
            : null,
          purchase_ledger_id: values?.purchase_ledger_id
            ? +values?.purchase_ledger_id
            : null,
          packing: values?.packing ? +values?.packing?.toFixed(2) : null,
          labour: values?.labour ? +values?.labour?.toFixed(2) : null,
          dalali: values?.dalali ? +values?.dalali?.toFixed(2) : null,
          total_amount: values?.grand_total ? +values?.grand_total : null,
          gst: values?.gst ? +values?.gst?.toFixed(2) : null,
          purchase_items: values?.purchase_items
            ? values?.purchase_items?.map((purchaseitem: any) => {
                return {
                  id: purchaseitem?.id ? +purchaseitem?.id : 0,
                  item_id: purchaseitem?.item_id
                    ? +purchaseitem?.item_id
                    : null,
                  // mrp: purchaseitem?.mrp ? +purchaseitem?.mrp : null,
                  quantity: purchaseitem?.quantity
                    ? +purchaseitem?.quantity
                    : 0,
                  free_quantity: purchaseitem?.free_quantity
                    ? +purchaseitem?.free_quantity
                    : 0,
                  unit_id: purchaseitem?.unit_id
                    ? +purchaseitem?.unit_id
                    : null,
                  rate: purchaseitem?.rate ? +purchaseitem?.rate : null,
                  discount: purchaseitem?.discount
                    ? +purchaseitem?.discount
                    : null,
                  discount_type: purchaseitem?.discount_type === "%" ? 2 : 1,
                  gst_id: purchaseitem?.gst_id
                    ? parseInt(purchaseitem?.gst_id.split("--")[0])
                    : null,
                  amount: purchaseitem?.amount ? +purchaseitem?.amount : null,
                };
              })
            : [],
        };
        if (PurchaseStateForGST === GUJARATIDFORGST) {
          insertPayload.cgst = values?.cgst ? +values?.cgst?.toFixed(2) : null;
          insertPayload.sgst = values?.sgst ? +values?.sgst?.toFixed(2) : null;
          insertPayload.igst = null;
        } else {
          insertPayload.igst = values?.igst ? +values?.igst?.toFixed(2) : null;
          insertPayload.cgst = null;
          insertPayload.sgst = null;
        }
        const response = await dispatch(createPurchase(insertPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate("/purchase");
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        throw error;
      }
    }
  };

  const handleInwardNoChange = async (ev: any) => {
    if (ev?.value) {
      const payload = {
        inward_no: ev?.value,
        financial_year: FINANCIAL_YEAR,
      };
      const res = await dispatch(getInwardByID(payload));
      if (res.meta?.requestStatus === "fulfilled") {
        dispatch(openDialog("inwardItemdetailsView"));
      }
    }
  };

  // if (loading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <>
      {loading && <LoadingPanel gridRef={gridRef} />}
      <InwardChildItemDialog />
      <Form
        key={formKey}
        onSubmit={handleSubmit}
        initialValues={PurchaseDetail}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement>
            <ShadowCard style={{ padding: 12 }}>
              <GridLayout
                style={{ marginRight: 30 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                ]}
              >
                <GridLayoutItem colSpan={4}>
                  <Typography.h4>
                    {purchase_guid ? "Update Purchase" : "Add Purchase"}
                  </Typography.h4>
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="purchase_no"
                    label="Purchase No"
                    disabled={true}
                    placeholder="Purchase No"
                    component={FormTextField}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem colSpan={3}></GridLayoutItem>
                {purchase_guid ? (
                  <GridLayoutItem colSpan={2}>
                    <Field
                      name="imward_no_label"
                      label="Inward No"
                      disabled={true}
                      placeholder="Inward No"
                      component={FormTextField}
                    />
                  </GridLayoutItem>
                ) : (
                  <GridLayoutItem
                    colSpan={2}
                    // style={{ display: "flex", alignItems: "end", gap: 10 }}
                  >
                    <div
                      style={{ display: "flex", alignItems: "end", gap: 10 }}
                    >
                      <Field
                        wrapperClassName="w-100"
                        name="inward_no"
                        label="Inward No"
                        placeholder="Inward No"
                        disabled={purchase_guid ? true : false}
                        component={FormSelectionField}
                        onChange={handleInwardNoChange}
                        options={InwardList?.map((inward: any) => {
                          return {
                            value: inward?.inward_no,
                            label: `${inward?.inward_no} - ${inward?.account_name} - ${inward?.inward_date} - PO#${inward?.po_no}`,
                          };
                        })}
                      />
                      <ButtonForAll
                        label={"View"}
                        disabled={
                          purchase_guid ||
                          !formRenderProps.valueGetter("inward_no")
                            ? true
                            : false
                        }
                        // style={{ position: "absolute", top: 35 }}
                        onClick={() =>
                          handleChallanNoChange(
                            formRenderProps.valueGetter("inward_no"),
                            formRenderProps
                          )
                        }
                      />
                    </div>
                  </GridLayoutItem>
                )}
                {/* <GridLayoutItem className="position-relative"></GridLayoutItem> */}
                <GridLayoutItem>
                  <Field
                    name="invoice_no"
                    label="Bill No"
                    placeholder="Bill No"
                    component={FormTextField}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="invoice_date"
                    label="Bill Date"
                    format="dd/MM/yyyy"
                    component={FormDatePicker}
                    validator={requiredValidator}
                    astrike={true}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="vendor_id"
                    label="Vendor Name"
                    placeholder="Vendor Name"
                    isAddNew={true}
                    addNewLink="account/create"
                    component={FormIncrementalSearch}
                    validator={requiredValidator}
                    astrike={true}
                    fetchIncrementalData={(search: any) =>
                      handleVendorSearchChange(
                        search,
                        `vendor_options`,
                        formRenderProps
                      )
                    }
                    options={
                      formRenderProps
                        .valueGetter("vendor_options")
                        ?.map((item: any) => {
                          return {
                            value: item.id,
                            label: item.account_name,
                          };
                        }) || []
                    }
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="mobile_no"
                    label="Mobile No"
                    placeholder="Mobile No"
                    component={FormTextField}
                    validator={phoneWOValueValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="email_id"
                    label="Email ID"
                    placeholder="Email ID"
                    component={FormTextField}
                    validator={emailWOValueValidator}
                  />
                </GridLayoutItem>
                {/* <GridLayoutItem>
                  <Field
                    name="place_of_supply"
                    label="Place Of Supply"
                    placeholder="Place Of Supply"
                    component={FormTextField}
                    validator={requiredValidator}
                  />
                </GridLayoutItem> */}
                <GridLayoutItem>
                  <Field
                    name="place_of_supply_id"
                    label="Place Of Supply"
                    placeholder="Place Of Supply"
                    component={FormSelectionField}
                    validator={requiredValidator}
                    astrike={true}
                    options={StateList?.map((state: IState) => {
                      return {
                        value: state?.id,
                        label: state?.state_name,
                      };
                    })}
                  />
                </GridLayoutItem>
                {/* <GridLayoutItem>
                  <Field
                    name="pan_no"
                    label="PAN No"
                    placeholder="PAN No"
                    component={FormTextField}
                    validator={panWOValueValidator}
                    onChange={(e) =>
                      formRenderProps.onChange("pan_no", {
                        value: e?.value?.toUpperCase(),
                      })
                    }
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="gst_no"
                    label="GST No"
                    placeholder="GST No"
                    component={FormTextField}
                    onChange={(e) =>
                      formRenderProps.onChange("gst_no", {
                        value: e?.value?.toUpperCase(),
                      })
                    }
                    validator={gstWOValueValidator}
                  />
                </GridLayoutItem> */}
                {purchase_guid && (
                  <GridLayoutItem>
                    <Field
                      name="po_no"
                      label="Purchase Order No"
                      placeholder="0"
                      component={FormTextField}
                      disabled={true}
                    />
                  </GridLayoutItem>
                )}
                <GridLayoutItem>
                  <Field
                    name="purchase_ledger_id"
                    label="Purchase Ledger"
                    placeholder="Purchase Ledger"
                    component={FormSelectionField}
                    // validator={requiredValidator}
                    options={AccountGroupList?.map(
                      (accountGroup: IAccountGroup) => {
                        return {
                          value: accountGroup?.id,
                          label: accountGroup?.group_name,
                        };
                      }
                    )}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    wrapperClassName="right-alighned-field"
                    name="terms_days"
                    label="Terms Day"
                    placeholder="0"
                    disabled={true}
                    maxNumber={999}
                    component={FormTextField}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="due_date"
                    label="Due Date"
                    format="dd/MM/yyyy"
                    disabled={true}
                    component={FormDatePicker}
                  />
                </GridLayoutItem>
                <QtyChangeWatcher formRenderProps={formRenderProps} />
                <VendorChangeWatcher formRenderProps={formRenderProps} />
                <DateChangeWatcher formRenderProps={formRenderProps} />
                <PurchaseNoChangeWatcher formRenderProps={formRenderProps} />
              </GridLayout>
            </ShadowCard>
            <ShadowCard style={{ padding: 12, marginTop: 10 }}>
              <GridLayout
                style={{ marginRight: 30 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                ]}
              >
                <GridLayoutItem colSpan={4}>
                  <Typography.h4>Item Details</Typography.h4>
                </GridLayoutItem>
                <GridLayoutItem
                  colSpan={4}
                  // style={{ borderBottom: "1px solid lightgray" }}
                >
                  <FieldArray
                    formRenderProps={formRenderProps}
                    handleItemSearchChange={handleItemSearchChange}
                    component={PurchaseItemDetailsArray}
                    name="purchase_items"
                  />
                </GridLayoutItem>
                <GridLayoutItem colSpan={2}>
                  <PurchaseInvoiceTaxSummary
                    formRenderProps={formRenderProps}
                  />
                </GridLayoutItem>
                <GridLayoutItem
                  colSpan={2}
                  style={{ display: "flex", alignItems: "end" }}
                >
                  <div style={{ width: "100%" }}>
                    <table style={{ width: "100%" }}>
                      <tr>
                        <td
                          style={{
                            width: "50%",
                            textAlign: "end",
                            fontWeight: 600,
                          }}
                        >
                          Total
                        </td>
                        <td style={{ width: "5%", textAlign: "center" }}>:</td>
                        <td style={{ width: "45%" }}>
                          <Field
                            wrapperClassName="calculation-label"
                            wrapperStyle={{ margin: 0, width: "100%" }}
                            name="taxable_amount"
                            format="n2"
                            component={FormNumericTextField}
                            disabled="true"
                          />
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            width: "50%",
                            textAlign: "end",
                            fontWeight: 600,
                          }}
                        >
                          Packing
                        </td>
                        <td style={{ width: "5%", textAlign: "center" }}>:</td>
                        <td style={{ width: "45%" }}>
                          <Field
                            wrapperClassName="right-alighned-field"
                            wrapperStyle={{ margin: 0, width: "100%" }}
                            name="packing"
                            format="n2"
                            placeholder="0.00"
                            component={FormNumericTextField}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            width: "50%",
                            textAlign: "end",
                            fontWeight: 600,
                          }}
                        >
                          Labour
                        </td>
                        <td style={{ width: "5%", textAlign: "center" }}>:</td>
                        <td style={{ width: "45%" }}>
                          <Field
                            wrapperClassName="right-alighned-field"
                            wrapperStyle={{ margin: 0, width: "100%" }}
                            name="labour"
                            format="n2"
                            placeholder="0.00"
                            component={FormNumericTextField}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            width: "50%",
                            textAlign: "end",
                            fontWeight: 600,
                          }}
                        >
                          Dalali
                        </td>
                        <td style={{ width: "5%", textAlign: "center" }}>:</td>
                        <td style={{ width: "45%" }}>
                          <Field
                            wrapperClassName="right-alighned-field"
                            wrapperStyle={{ margin: 0, width: "100%" }}
                            name="dalali"
                            format="n2"
                            placeholder="0.00"
                            component={FormNumericTextField}
                          />
                        </td>
                      </tr>
                      {PurchaseStateForGST === GUJARATIDFORGST && (
                        <tr>
                          <td
                            style={{
                              width: "50%",
                              textAlign: "end",
                              fontWeight: 600,
                            }}
                          >
                            CGST
                          </td>
                          <td style={{ width: "5%", textAlign: "center" }}>
                            :
                          </td>
                          <td style={{ width: "45%" }}>
                            <Field
                              wrapperClassName="calculation-label"
                              wrapperStyle={{ margin: 0, width: "100%" }}
                              name="cgst"
                              placeholder="0"
                              disabled={true}
                              format="n2"
                              component={FormNumericTextField}
                            />
                          </td>
                        </tr>
                      )}
                      {PurchaseStateForGST === GUJARATIDFORGST && (
                        <tr>
                          <td
                            style={{
                              width: "50%",
                              textAlign: "end",
                              fontWeight: 600,
                            }}
                          >
                            SGST
                          </td>
                          <td style={{ width: "5%", textAlign: "center" }}>
                            :
                          </td>
                          <td style={{ width: "45%" }}>
                            <Field
                              wrapperClassName="calculation-label"
                              wrapperStyle={{ margin: 0, width: "100%" }}
                              name="sgst"
                              placeholder="0"
                              disabled={true}
                              format="n2"
                              component={FormNumericTextField}
                            />
                          </td>
                        </tr>
                      )}
                      {PurchaseStateForGST !== GUJARATIDFORGST && (
                        <tr>
                          <td
                            style={{
                              width: "50%",
                              textAlign: "end",
                              fontWeight: 600,
                            }}
                          >
                            IGST
                          </td>
                          <td style={{ width: "5%", textAlign: "center" }}>
                            :
                          </td>
                          <td style={{ width: "45%" }}>
                            <Field
                              wrapperClassName="calculation-label"
                              wrapperStyle={{ margin: 0, width: "100%" }}
                              name="igst"
                              placeholder="0"
                              disabled={true}
                              format="n2"
                              component={FormNumericTextField}
                            />
                          </td>
                        </tr>
                      )}
                      <tr>
                        <td
                          style={{
                            width: "50%",
                            textAlign: "end",
                            fontWeight: 600,
                          }}
                        >
                          Sub Total
                        </td>
                        <td style={{ width: "5%", textAlign: "center" }}>:</td>
                        <td style={{ width: "45%" }}>
                          <Field
                            wrapperClassName="calculation-label"
                            wrapperStyle={{ margin: 0, width: "100%" }}
                            name="sub_total"
                            placeholder="0"
                            disabled={true}
                            format="n2"
                            component={FormNumericTextField}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            width: "50%",
                            textAlign: "end",
                            fontWeight: 600,
                          }}
                        >
                          Round Off
                        </td>
                        <td style={{ width: "5%", textAlign: "center" }}>:</td>
                        <td style={{ width: "45%" }}>
                          <Field
                            wrapperClassName="calculation-label"
                            wrapperStyle={{ margin: 0, width: "100%" }}
                            name="round_off"
                            placeholder="0"
                            disabled={true}
                            format="n2"
                            component={FormNumericTextField}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            width: "50%",
                            textAlign: "end",
                            fontWeight: 600,
                          }}
                        >
                          Grand Total
                        </td>
                        <td style={{ width: "5%", textAlign: "center" }}>:</td>
                        <td style={{ width: "45%" }}>
                          <Field
                            wrapperClassName="calculation-label"
                            wrapperStyle={{ margin: 0, width: "100%" }}
                            name="grand_total"
                            placeholder="0"
                            disabled={true}
                            format="n2"
                            component={FormNumericTextField}
                          />
                        </td>
                      </tr>
                    </table>
                  </div>
                </GridLayoutItem>
                <GridLayoutItem
                  colSpan={4}
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "end",
                    marginTop: 15,
                  }}
                >
                  <div>
                    <ButtonWithLoading
                      label={purchase_guid ? "Update" : "Save"}
                      type="submit"
                      disabled={!formRenderProps.allowSubmit || loading}
                      loading={loading}
                    />
                    <Button
                      type="button"
                      fillMode={"outline"}
                      themeColor={"primary"}
                      style={{ marginLeft: 4 }}
                      onClick={() => navigate("/purchase")}
                    >
                      Cancel
                    </Button>
                  </div>
                </GridLayoutItem>
              </GridLayout>
            </ShadowCard>
          </FormElement>
        )}
      />
    </>
  );
};

const InwardChildItemDialog: React.FC = () => {
  // const dispatch = useAppDispatch();
  const gridRef = useRef<any>(null);
  // const InwardID = useAppSelector((state) => state.inward.InwardID);
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const InwardDetail = useAppSelector((state) => state.inward.InwardDetail);
  const loading = useAppSelector((state) => state.inward.loading);

  // useEffect(() => {
  //   if (InwardID) {
  //     const payload = {
  //       inward_guid: InwardID,
  //     };
  //     dispatch(getInwardByID(payload));
  //   }
  // }, [InwardID]);

  // useEffect(() => {
  //   return () => {
  //     dispatch(clearInwardDetails());
  //   };
  // }, []);

  let totalDagina = 0;
  let totalAmount = 0;

  InwardDetail &&
    InwardDetail?.inward_items &&
    InwardDetail?.inward_items.length > 0 &&
    InwardDetail?.inward_items?.forEach((item: any) => {
      totalDagina += item?.dagina || 0;
      totalAmount += item?.total || 0;
    });

  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      {dialogName === "inwardItemdetailsView" && (
        <AppDialog className="inward-items-dialog">
          <>
            <Typography.h5>{"Inward Items"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                {InwardDetail &&
                InwardDetail?.inward_items &&
                InwardDetail?.inward_items.length > 0 ? (
                  <div
                    className="table-responsive m-0"
                    style={{
                      maxHeight: "calc(100vh - 227px)",
                      scrollbarWidth: "thin",
                    }}
                  >
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th style={{ background: "black", color: "white" }}>
                            No
                          </th>
                          <th style={{ background: "black", color: "white" }}>
                            Item
                          </th>
                          <th style={{ background: "black", color: "white" }}>
                            Weight (Qty)
                          </th>
                          <th style={{ background: "black", color: "white" }}>
                            Rate
                          </th>
                          <th style={{ background: "black", color: "white" }}>
                            Unit
                          </th>
                          <th style={{ background: "black", color: "white" }}>
                            Dagina
                          </th>
                          <th style={{ background: "black", color: "white" }}>
                            Total
                          </th>
                          <th style={{ background: "black", color: "white" }}>
                            Warehouse
                          </th>
                          <th style={{ background: "black", color: "white" }}>
                            Warehouse Receipt No
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {InwardDetail?.inward_items?.map(
                          (item: any, index: number) => (
                            <tr>
                              <td>{index + 1}</td>
                              <td>{item?.item_name}</td>
                              <td className="text-end">
                                {formatIndianNumberForQty(item?.weight || 0)}
                              </td>
                              <td className="text-end">
                                {formatIndianNumber(item?.rate || 0, 4)}
                              </td>
                              <td>{item?.unit_name}</td>
                              <td className="text-end">{item?.dagina}</td>
                              <td className="text-end">
                                {item?.total
                                  ? formatIndianNumber(item?.total)
                                  : ""}
                              </td>
                              <td>{item?.warehouse_name}</td>
                              <td>{item?.receipt_no}</td>
                            </tr>
                          )
                        )}
                        <tr className="fw-bold">
                          <td
                            colSpan={5}
                            className="text-end pe-5"
                            style={{
                              background: "lightgray",
                              color: "black",
                            }}
                          >
                            Total
                          </td>
                          <td
                            style={{
                              textAlign: "end",
                              background: "lightgray",
                              color: "black",
                            }}
                          >
                            {totalDagina}
                          </td>
                          <td
                            style={{
                              textAlign: "end",
                              background: "lightgray",
                              color: "black",
                            }}
                          >
                            {formatIndianNumber(Math.round(totalAmount))}
                          </td>
                          <td
                            style={{
                              background: "lightgray",
                              color: "black",
                            }}
                          ></td>
                          <td
                            style={{
                              background: "lightgray",
                              color: "black",
                            }}
                          ></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <AlertBox style={{ marginBottom: 10 }} />
                )}
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                  marginTop: 20,
                }}
              >
                <DialogCloseButton themeColor="error" />
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
    </>
  );
};

const PurchaseInvoiceTaxSummary: React.FC<FormChangeWatcherProps> = ({
  formRenderProps,
}) => {
  const location = useLocation();
  const PurchaseStateForGST = useAppSelector(
    (state) => state.purchase.PurchaseStateForGST
  );
  const purchase_guid = location.state?.purchase_guid;
  const PurchaseDetail = useAppSelector(
    (state) => state.purchase.PurchaseDetail
  );
  const isVendorRef = useRef(true);
  const [isIGST, setIsIgst] = React.useState<boolean>(false);

  const totalGST = formRenderProps.valueGetter("gst");
  const totalGST5 = formRenderProps.valueGetter("gst5");
  const totalGST12 = formRenderProps.valueGetter("gst12");
  const totalGST18 = formRenderProps.valueGetter("gst18");
  const totalGST28 = formRenderProps.valueGetter("gst28");

  useEffect(() => {
    if (purchase_guid) {
      if (!isVendorRef.current) {
        setIsIgst(PurchaseStateForGST !== GUJARATIDFORGST);
      } else {
        if (PurchaseDetail?.igst && PurchaseDetail?.igst !== null) {
          setIsIgst(true);
        } else {
          setIsIgst(false);
        }
        isVendorRef.current = false;
      }
    } else {
      setIsIgst(PurchaseStateForGST !== GUJARATIDFORGST);
    }
  }, [PurchaseStateForGST]);

  return (
    <>
      <div>
        <table style={{ width: "100%" }} className="sctaxsummary-table">
          {(totalGST5 > 0 ||
            totalGST12 > 0 ||
            totalGST18 > 0 ||
            totalGST28 > 0) && (
            <tr style={{ background: "lightgray" }}>
              <td colSpan={4}>
                <h5 className="m-0">Tax Summery</h5>
              </td>
            </tr>
          )}
          {isIGST && totalGST5 > 0 && (
            <tr>
              <td
                style={{
                  width: "30%",
                  fontWeight: 600,
                }}
              >
                IGST 5%
              </td>
              <td style={{ width: "10%", textAlign: "center" }}>:</td>
              <td style={{ width: "10%", textAlign: "center" }}>5 %</td>
              <td
                style={{
                  width: "50%",
                  textAlign: "right",
                  paddingRight: 45,
                }}
              >
                {formatIndianNumber(totalGST5)}
              </td>
            </tr>
          )}
          {isIGST && totalGST12 > 0 && (
            <tr>
              <td
                style={{
                  width: "30%",
                  fontWeight: 600,
                }}
              >
                IGST 12%
              </td>
              <td style={{ width: "10%", textAlign: "center" }}>:</td>
              <td style={{ width: "10%", textAlign: "center" }}>12 %</td>
              <td
                style={{
                  width: "50%",
                  textAlign: "right",
                  paddingRight: 45,
                }}
              >
                {formatIndianNumber(totalGST12)}
              </td>
            </tr>
          )}
          {isIGST && totalGST18 > 0 && (
            <tr>
              <td
                style={{
                  width: "30%",
                  fontWeight: 600,
                }}
              >
                IGST 18%
              </td>
              <td style={{ width: "10%", textAlign: "center" }}>:</td>
              <td style={{ width: "10%", textAlign: "center" }}>18 %</td>
              <td
                style={{
                  width: "50%",
                  textAlign: "right",
                  paddingRight: 45,
                }}
              >
                {formatIndianNumber(totalGST18)}
              </td>
            </tr>
          )}
          {isIGST && totalGST28 > 0 && (
            <tr>
              <td
                style={{
                  width: "30%",
                  fontWeight: 600,
                }}
              >
                IGST 28%
              </td>
              <td style={{ width: "10%", textAlign: "center" }}>:</td>
              <td style={{ width: "10%", textAlign: "center" }}>28 %</td>
              <td
                style={{
                  width: "50%",
                  textAlign: "right",
                  paddingRight: 45,
                }}
              >
                {formatIndianNumber(totalGST28)}
              </td>
            </tr>
          )}
          {!isIGST && totalGST5 > 0 && (
            <>
              <tr>
                <td
                  style={{
                    width: "30%",
                    fontWeight: 600,
                  }}
                >
                  CGST 2.5%
                </td>
                <td style={{ width: "10%", textAlign: "center" }}>:</td>
                <td style={{ width: "10%", textAlign: "center" }}>2.5 %</td>
                <td
                  style={{
                    width: "50%",
                    textAlign: "right",
                    paddingRight: 45,
                  }}
                >
                  {formatIndianNumber(totalGST5 / 2)}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    width: "30%",
                    fontWeight: 600,
                  }}
                >
                  SGST 2.5%
                </td>
                <td style={{ width: "10%", textAlign: "center" }}>:</td>
                <td style={{ width: "10%", textAlign: "center" }}>2.5 %</td>
                <td
                  style={{
                    width: "50%",
                    textAlign: "right",
                    paddingRight: 45,
                  }}
                >
                  {formatIndianNumber(totalGST5 / 2)}
                </td>
              </tr>
            </>
          )}
          {!isIGST && totalGST12 > 0 && (
            <>
              <tr>
                <td
                  style={{
                    width: "30%",
                    fontWeight: 600,
                  }}
                >
                  CGST 6%
                </td>
                <td style={{ width: "10%", textAlign: "center" }}>:</td>
                <td style={{ width: "10%", textAlign: "center" }}>6 %</td>
                <td
                  style={{
                    width: "50%",
                    textAlign: "right",
                    paddingRight: 45,
                  }}
                >
                  {formatIndianNumber(totalGST12 / 2)}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    width: "30%",
                    fontWeight: 600,
                  }}
                >
                  SGST 6%
                </td>
                <td style={{ width: "10%", textAlign: "center" }}>:</td>
                <td style={{ width: "10%", textAlign: "center" }}>6 %</td>
                <td
                  style={{
                    width: "50%",
                    textAlign: "right",
                    paddingRight: 45,
                  }}
                >
                  {formatIndianNumber(totalGST12 / 2)}
                </td>
              </tr>
            </>
          )}
          {!isIGST && totalGST18 > 0 && (
            <>
              <tr>
                <td
                  style={{
                    width: "30%",
                    fontWeight: 600,
                  }}
                >
                  CGST 9%
                </td>
                <td style={{ width: "10%", textAlign: "center" }}>:</td>
                <td style={{ width: "10%", textAlign: "center" }}>9 %</td>
                <td
                  style={{
                    width: "50%",
                    textAlign: "right",
                    paddingRight: 45,
                  }}
                >
                  {formatIndianNumber(totalGST18 / 2)}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    width: "30%",
                    fontWeight: 600,
                  }}
                >
                  SGST 9%
                </td>
                <td style={{ width: "10%", textAlign: "center" }}>:</td>
                <td style={{ width: "10%", textAlign: "center" }}>9 %</td>
                <td
                  style={{
                    width: "50%",
                    textAlign: "right",
                    paddingRight: 45,
                  }}
                >
                  {formatIndianNumber(totalGST18 / 2)}
                </td>
              </tr>
            </>
          )}
          {!isIGST && totalGST28 > 0 && (
            <>
              <tr>
                <td
                  style={{
                    width: "30%",
                    fontWeight: 600,
                  }}
                >
                  CGST 14%
                </td>
                <td style={{ width: "10%", textAlign: "center" }}>:</td>
                <td style={{ width: "10%", textAlign: "center" }}>14 %</td>
                <td
                  style={{
                    width: "50%",
                    textAlign: "right",
                    paddingRight: 45,
                  }}
                >
                  {formatIndianNumber(totalGST28 / 2)}
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    width: "30%",
                    fontWeight: 600,
                  }}
                >
                  SGST 14%
                </td>
                <td style={{ width: "10%", textAlign: "center" }}>:</td>
                <td style={{ width: "10%", textAlign: "center" }}>14 %</td>
                <td
                  style={{
                    width: "50%",
                    textAlign: "right",
                    paddingRight: 45,
                  }}
                >
                  {formatIndianNumber(totalGST28 / 2)}
                </td>
              </tr>
            </>
          )}

          {!isIGST && totalGST > 0 && (
            <>
              <tr style={{ background: "whitesmoke" }}>
                <td
                  style={{
                    width: "30%",
                    fontWeight: 600,
                  }}
                >
                  Total CGST
                </td>
                <td style={{ width: "10%", textAlign: "center" }}>:</td>
                <td style={{ width: "10%", textAlign: "center" }}></td>
                <td
                  style={{
                    width: "50%",
                    textAlign: "right",
                    paddingRight: 45,
                  }}
                >
                  {formatIndianNumber(totalGST / 2)}
                </td>
              </tr>
              <tr style={{ background: "whitesmoke" }}>
                <td
                  style={{
                    width: "30%",
                    fontWeight: 600,
                  }}
                >
                  Total SGST
                </td>
                <td style={{ width: "10%", textAlign: "center" }}>:</td>
                <td style={{ width: "10%", textAlign: "center" }}></td>
                <td
                  style={{
                    width: "50%",
                    textAlign: "right",
                    paddingRight: 45,
                  }}
                >
                  {formatIndianNumber(totalGST / 2)}
                </td>
              </tr>
            </>
          )}
          {isIGST && totalGST > 0 && (
            <tr style={{ background: "whitesmoke" }}>
              <td
                style={{
                  width: "30%",
                  fontWeight: 600,
                }}
              >
                Total IGST
              </td>
              <td style={{ width: "10%", textAlign: "center" }}>:</td>
              <td style={{ width: "10%", textAlign: "center" }}></td>
              <td
                style={{
                  width: "50%",
                  textAlign: "right",
                  paddingRight: 45,
                }}
              >
                {formatIndianNumber(totalGST)}
              </td>
            </tr>
          )}
        </table>
      </div>
    </>
  );
};

export default CreatePurchase;
